@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
}


body{
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}

/* body {
    background-color: #f5f5f5;
} */

@media (max-width:1100px) {
    .hero{
        height: 67vh;
    }
    
}
footer {
    button:hover {
        color: #000 !important;
    }
}

.px-auto {
    padding: 0px auto;
}

/* 
@media (max-width:1100px) {


    footer {
        .icons {
            position: fixed;
            display: flex;
            flex-direction: column;
            right: 0;
            justify-content: end;
            align-items: end;
            padding: 0 5px;
            bottom: 20px;
            /* transform: translate(); */
            /* transition: all .2s linear; */
        /* }
    }
} */ 
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f8f9fa; /* Light gray background */
  }
  .container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Adjust as needed */
    margin: 50px auto; /* Center align container */
  }
  .jumbotron {
    padding: 30px;
    border-radius: 8px;
    background-color: #f95c91; /* otstrap primary color */
    color: white;
  }
  h2 {
    margin-top: 40px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
  }
  .display-4 {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    .display-4 {
      font-size: 1.5rem;
    }
  }